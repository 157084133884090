import React, { useState } from 'react';
 // import logo from './logo.svg';
import axios from 'axios'; //Import Axios
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// セレクトボックスにセットしたい項目の配列 items を作成します
const items = [
  {  item: "ALL" ,id: "0"},
  {  item: "私立恵比寿中学関連" ,id: "1"},
  { item: "超特急関連" ,id: "2"}
];
// map（）で配列itemsから要素を1つずつ取り出し、各要素をoptionタグに設定していきます
const SelectItems = items.map((item) => {
  return (
    // value:選択された際に渡される値
    // key:optionタグ内で一意になる値
    <option value={item.id} key={item.id}>
      {item.item}
    </option>
  );
});

const scurl = "https://ebcinsta.sakura.ne.jp/bot/shortcode/";
const xurl = "https://ebcinsta.sakura.ne.jp/instashow.php?code=";

const checkFileExistence =   function(sc) {
  const url=scurl + sc + ".jpg";
  const url2=scurl + sc + "-0.jpg";
  var xhr = new XMLHttpRequest();
  xhr.open("HEAD", url, false);
  xhr.send(null);
  var status = xhr.status;
  if (status === 404) {
    return url2;
  } else {
    return url;
  }
};

const InputSelectBox = () => {
  const [selectedValue, setSelectedValue] = useState(items[0].id );
  const [data, setData] = useState('');
  const handleChange = (e) => {
    
    // セレクトボックスで選択された値(value)を状態変数（selectedValue）に設定する
    setSelectedValue(e.target.value);
    const url ="https://ebcinsta.sakura.ne.jp/bot/db.php"
    axios.get(url,{
     params:{
       acc:e.target.value,
       col:7
     },
     headers: {
       'X-Requested-With': 'XMLHttpRequest' 
     }
    })
    .then(function(response){
     setData(response.data);
    });
   }

  return (
    <div className="App">
    アカウントを選択してください
    <select onChange={handleChange}>
      {SelectItems}
    </select>

<table>
            <thead>
                <tr>
                    <th>no</th>
                    <th>img</th>
                    <th>shortcode</th>
                    <th>timestamp</th>
                    <th>IGaccount</th>
                </tr>
            </thead>
            <tbody>
            {Object.keys(data).map((key, ii) => (
                    <tr key={ii}>
                        <td>{ii}</td>
                        <td><img src={checkFileExistence(data[key].shortcode)} height="120"></img></td>
                        <td>{xurl+data[key].shortcode}</td>
                        <td>{Date(data[key].timestamp * 1000)}</td>
                        <td>{data[key].IGaccount}</td>
                     </tr>
                    ))}
               </tbody>
            </table>
    </div>
  );
};

export default function App() {
  return <InputSelectBox />;
}